import React from 'react';
import './TechNugget.css';
export default class TechNugget extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }
    render() {
        return (
            <div className="ProjectTechNugget">{this.props.data}</div>
        )
    }
};