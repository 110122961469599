import React from 'react';
import './Contact.css';

export default class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            activeMessage: {
                email: "",
                reason: "",
                message: "",
            }
        };
        this.emailChange = this.emailChange.bind(this);
        this.messageChange = this.messageChange.bind(this);
        this.reasonChange = this.reasonChange.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };
    emailChange(e) {
        const email = e.target.value;
        this.setState({
            activeMessage: {
                ...this.state.activeMessage,
                email: email,
            }
        })
    }
    reasonChange(e) {
        const reason = e.target.value;
        this.setState({
            activeMessage: {
                ...this.state.activeMessage,
                reason: reason,
            }
        })
    }
    messageChange(e) {
        const message = e.target.value;
        this.setState({
            activeMessage: {
                ...this.state.activeMessage,
                message: message,
            }
        })
    }
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    handleSubmit(e) {
        e.preventDefault();
        var csrftoken = this.getCookie("csrftoken");
        fetch("https://www.fullstackgrub.com/api/send-mail/", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify(this.state.activeMessage)
        }).then(() => {
            this.setState({
                activeMessage: {
                    email: "",
                    reason: "",
                    message: "",
                }
            });
            window.alert("Message Sent :)");
        })
    }
    render() {
        return (
            <div className="ContactSectionWrapper">
                <div className="ContactFormWrapper">
                    <form onSubmit={this.handleSubmit}>
                        <div className="EmailInputWrapper">
                            <input
                                type="text"
                                className="EmailInput"
                                value={this.state.activeMessage.email}
                                name="email"
                                placeholder="Email"
                                maxLength="50"
                                onChange={this.emailChange}
                                required
                            />
                        </div>
                        <div className="ReasonInputWrapper">
                            <input
                                type="text"
                                className="ReasonInput"
                                value={this.state.activeMessage.reason}
                                name="reason"
                                placeholder="Subject"
                                maxLength="50"
                                onChange={this.reasonChange}
                                required

                            />

                        </div>
                        <div className="MessageInputWrapper">
                            <textarea
                                className="MessageInput"
                                value={this.state.activeMessage.message}
                                name="message"
                                placeholder="Message"
                                maxLength="1000"
                                onChange={this.messageChange}
                                required
                            />
                            <div className="MessageCount">{this.state.activeMessage.message.length} /1000</div>
                        </div>
                        <div className="ContactSubmitWrapper">
                            <input type="Submit" value="Send" className="ContactSubmit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}