import React from 'react';
import './Projects.css';
import TechNugget from './TechNugget.js';
import ReadMore from './ReadMore.js';
export default class Projects extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.ProjectTitleWrapperRef = React.createRef();
        this.TitleTechAnim = this.TitleTechAnim.bind(this);
        this.TitleAnimReset = this.TitleAnimReset.bind(this);
        this.ProjectImageRef = React.createRef();
    }
    TitleTechAnim() {
        if (window.innerWidth < 1150 && window.innerWidth > 845) {
            this.ProjectTitleWrapperRef.current.style.width = "700px"
        } else if (window.innerWidth < 845) {
            this.ProjectTitleWrapperRef.current.style.width = "100%"
        } else {
            this.ProjectTitleWrapperRef.current.style.width = "900px"
        }
    };
    TitleAnimReset() {
        if (window.innerWidth < 1150 && window.innerWidth > 845) {
            this.ProjectTitleWrapperRef.current.style.width = "100%"
        } else if (window.innerWidth < 845) {
            this.ProjectTitleWrapperRef.current.style.width = "100%"
        } else {
            this.ProjectTitleWrapperRef.current.style.width = "100%"
        }

    };
    render() {
        const self = this;
        const TechNuggetsMapped = this.props.data.techUsed.map((i) => <TechNugget data={i} />)
        return (
            <div className="ProjectSectionWrapper" onMouseOver={() => self.TitleTechAnim()} onMouseOut={() => self.TitleAnimReset()} id="projSecID">
                <div className="ProjectCardWrapper">
                    <div className="ProjectPicWrapper">
                        <div className="ProjectPic" style={{ backgroundImage: "url(" + this.props.data.image + ")" }} ref={this.ProjectImageRef}></div>
                    </div>
                    <div className="ProjectMidDesign">
                        <div className="ProjectMidDesignTop"></div>
                        <div className="ProjectMidDesignBottom"></div>
                    </div>
                    <div className="ProjectTitleWrapper" ref={this.ProjectTitleWrapperRef}>
                        <div className="ProjectTitle">{this.props.data.title}</div>
                        <div className="ProjectShortDescription">{this.props.data.type}</div>
                        <div className="ProjectTechUsedWrapper">
                            <div className="ProjectTechUsed">
                                {TechNuggetsMapped}
                            </div>
                        </div>
                    </div>
                    <div className="ProjectFooter">
                        <div className="ProjectFooterSection">
                            <div className="ProjectFooterTitle">
                                Frontend
                            </div>
                            <div className="ProjectFooterDesc">{this.props.data.frontend}</div>
                        </div>
                        <div className="ProjectFooterSection">
                            <div className="ProjectFooterTitle">
                                Est.
                            </div>
                            <div className="ProjectFooterDesc">{this.props.data.est}</div>
                        </div>
                        <div className="ProjectFooterSection">
                            <div className="ProjectFooterTitle">
                                Backend
                            </div>
                            <div className="ProjectFooterDesc">{this.props.data.backend}</div>
                        </div>
                    </div>
                </div>
                <div className="ProjectDescriptionWrapper">
                    <div className="ProjectDescription">
                        {this.props.data.description}
                    </div>
                    {/* {this.props.data.more && (<ReadMore data={this.props.data} modalShow={this.props.modalShow} />)} */}
                    <div className="ProjectDescriptionButtons">
                        <div className="ProjectButtonOne" onClick={() => window.open("https://github.com/Grub1000", "_blank")}><div className="ProjectButtonText">Source</div></div>
                        <a className="LinkElement" onClick={() => { if (!this.props.data.link == "") { window.open(this.props.data.link) } }}><div className="ProjectButtonTwo" ><div className="ProjectButtonText">Visit Site</div></div></a>
                    </div>
                </div>
            </div>
        )
    }
}