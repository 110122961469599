import React from 'react';
import './Modal.css'
export default class Modal extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        const newText = this.props.data.extendedDescription.split('\n').map(str => <div>{str}<br /></div>);
        return (
            <div className="ModalSuperWrapper" id="modal">
                <div className="ModalHeaderSuperWrapper"></div>
                <div className="ModalTitle">Read More</div>
                <div className="ModalBodySuperWrapper">
                    <div className="ModalBodyWrapper">{newText}</div>
                </div>
                <div className="ModalFooterSuperWrapper"></div>
            </div>
        )
    }
}