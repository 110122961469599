import React from 'react';
import './About.css';
import greenstaarLogo from '../src/greenstaarWhite.png';

export default class About extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    };
    render() {
        return (
            <div className="AboutSectionWrapper">
                <div className="AboutTitle">Skills and Tech</div>
                <div className="AboutSkillsWrapper">
                    <div className="AboutSkills">Python</div>
                    <div className="AboutSkills">Django</div>
                    <div className="AboutSkills">Javascript</div>
                    <div className="AboutSkills">React</div>
                    <div className="AboutSkills">PHP</div>
                    <div className="AboutSkills">Laravel</div>
                    <div className="AboutSkills">FabricJS</div>
                    <div className="AboutSkills">NodeJS</div>
                    <div className="AboutSkills">MySQL/AWS(S3)</div>
                    <div className="AboutSkills">CSS3</div>
                    <div className="AboutSkills">SCSS</div>
                    <div className="AboutSkills">HTML5</div>
                    <div className="AboutSkills">Bootstrap</div>
                    <div className="AboutSkills">EC2</div>
                    <div className="AboutSkills">Linode Servers</div>
                    <div className="AboutSkills">Apache2</div>
                    <div className="AboutSkills">C++</div>
                    <div className="AboutSkills">XAMPP</div>
                    <div className="AboutSkills">GIMP</div>
                    <div className="AboutSkills">Git</div>
                    <div className="AboutSkills">Windows/Linux</div>
                    <div className="AboutSkills">Design</div>
                    <div className="AboutSkills">Web Server Setup/Management</div>
                    <div className="AboutSkills">Deployment</div>
                </div>
                <div className="AboutTitle">Summary</div>
                <div className="AboutMessageOne">
                    Hi!, I'm a<mark> 21 year old software engineer based in Orange County, California.</mark> My two passions are in mathematics and technology. Fresh out of highschool and with a burning desire to increase my footprint on the earth, I decided to combine my passions into programming. Although the road is difficult at times, I prevail knowing that this feeling of adventure and hardship, met with resolution, is what I love so much about life. I plan to be much more than a fullstack developer in the future, but the road starts here for me.<mark> If I can be of service to good-willed people, there is nothing better.</mark>
                </div>
                <div className="AboutWESuperWrapper">
                    <div className="AboutTitle">Work Experience</div>
                    <div className="AboutWEWrapper">
                        <div className="greenstarLogo"></div>
                        <div className="AboutWEPosition">Full-Stack Web Developer</div>
                        <div className="AboutWETime">April - Aug 2021 </div>
                    </div>
                </div>
            </div>
        )
    }
}
