import React from 'react';
import './ReadMore.css';

export default class ReadMore extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return (
            <div className="ReadMoreSuperWrapper" onClick={() => { this.props.modalShow(this.props.data) }}>. . . Read More</div>
        )
    }
}