import React from 'react';
import './App.css';
import Projects from './Projects.js';
import Education from './Education.js';
import About from './About.js';
import Contact from './Contact.js';
import Modal from './Modal.js';
import backGroundOne from "../src/BeatBoxResponsiveSmall.png";
import backGroundTwo from "../src/MerchStoreResponsiveSmall.png";
import backGroundThree from "../src/PipeLineResponsiveSmall.png";
import backGroundFour from "../src/TaskAppResponsiveSmall.png";
import backGroundFive from "../src/EsignatureResponsiveSmall.png";
import backGroundSix from "../src/AlgoAppResponsiveSmall.png";
import backGroundSeven from "../src/ConfidentialScreensDarkResponsiveSmall.png";
import backGroundEight from "../src/VDMDemoTranSmall.png";
export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      Home: true,
      Projects: false,
      Education: false,
      Contact: false,
      Modal: false,
      ProjectsList: [
        { image: backGroundEight, title: "VDM Demo", type: "Service", description: "Using the same technology I used while working at GreenStaar I created a Demo version of the web based Variable Data Mapping tool for pdf's I'd made.", frontend: "React", backend: "laravel", est: "2022", techUsed: ["Laravel", "React", "Javascript", "HTML", "CSS", "MySql", "RESTapi", "Responsive"], link: "https://vdm.fullstackgrub.com/", more: false, extendedDescription: "" },
        { image: backGroundSeven, title: "Thegroapp.com", type: "Work / Job", description: "The scope of my work was to create a browser based PDF editor combined with a workspace manager that allows a user to input a PDF file, such as a premade label, and add new variable fields onto said label such as barcodes, text fields, and more. You create a workspace for each PDF; in which you have access to a full custom built editor, saves, and other tools.", frontend: "React", backend: "Laravel", est: "2021", techUsed: ["Laravel", "React", "Javascript", "HTML", "CSS", "MySql", "RESTapi", "Responsive"], link: "https://thegroapp.com", more: false, extendedDescription: "While working at Greenstaar I was tasked to maintain / add to the large existing codebase. I was also tasked with creating a flagship web app that would take care of the tediousness that was adding to the variable label system at the time. Using PHP, React, SCSS, MySQL and many other languages and libraries I was able to create an editor complete with all the features that would allow greater automation within the companies variable label creation branch. Along with the editor comes an editor entry point / main-menu where you can manage your active workspaces and much more features that are confidential. I had to learn alot about many new technologies during my time working on this beast of a project that I will definitely use in the future. It was a great learning experience seeing how I handle working on something so complex and problem solve at every corner the project turned. I gained the confidence to say that I am a problem-solver first and an engineer / programmer second. \n - Jorge Ramirez \n" },
        { image: backGroundSix, title: "Traverse", type: "Science", description: "This React-Django application was made in order to showcase the many types of traversal algorithms out there in an easy to use visualizer. I chose Dijkstra's Algorithm to be the first one I would implement into code. Shortest path algorithms are the best, aren't they?", frontend: "React", backend: "Django", est: "2021", techUsed: ["React", "Django", "JavaScript", "HTML", "CSS"], link: "https://www.fullstackgrub.com/algoapps/traverse", more: false, extendedDescription: "" },
        { image: backGroundOne, title: "BeatBox", type: "Social Media", description: "This Django-only application incorporates an infinite-scrolling feature created with jquery Waypoints and Django Paganation. Fully fitted with the abilities to Create, Update, Delete, and Like depending on user status. Given the fact that the media (images/gifs) are stored in S3 buckets, you are more than welcome to use it without the fear of overloading the server.", frontend: "Django", backend: "Django", est: "2020", techUsed: ["Django", "JavaScript", "HTML", "CSS", "MySql", "AWS(S3)", "JQuery", "Responsive"], link: "https://www.fullstackgrub.com/beatbox", more: false, extendedDescription: "" },
        { image: backGroundTwo, title: "MerchStore", type: "eCommerce", description: "This Django/React application incorporates an in-house search engine to make looking for product a little easier. The purpose of this application was to develop a merch store mock-up full with a cart and search feature. This application does also contain a sudo admin mode where the users can see and use the Create, Update, and Delete features in the current version.", frontend: "React", backend: "Django", est: "2020", techUsed: ["React", "Django", "JavaScript", "HTML", "CSS", "MySql", "AWS(S3)", "RESTapi", "Responsive"], link: "https://www.fullstackgrub.com/merchstore", more: false, extendedDescription: "" },
        { image: backGroundThree, title: "PipeLine", type: "Service", description: "This Django-only application incorporates a steam account search feature built using steams Web Api. After entering your steam accounts specific ID, or Vanity ID, the application will deliver an info/stats card giving the user some useful information about their steam activity. This is similar to a game rank-checking website you've probably used before. ", frontend: "Django", backend: "Django", est: "2020", techUsed: ["Django", "JavaScript", "HTML", "CSS", "SteamApi", "Responsive"], link: "https://www.fullstackgrub.com/pipeline", more: false, extendedDescription: "" },
        { image: backGroundFour, title: "TaskApp", type: "Service", description: "This Django/React application is my take on the typical task app project so many have done before me. My little Full-Stack twist on it was to add a simple login feature on top of the persistant tasks that are saved in a database for later use by the user. Thats about it for this one.  ", frontend: "React", backend: "Django", est: "2020", techUsed: ["React", "Django", "JavaScript", "HTML", "CSS", "MySql", "AWS(S3)", "RESTapi", "Responsive"], link: "https://www.fullstackgrub.com/taskapp", more: false, extendedDescription: "" },
        { image: backGroundFive, title: "Esignature", type: "Test", description: "This very simple application was made to test an Esignature field concept I was briefed on. It worked just fine and only took about 30 minutes to implement myself.", frontend: "None", backend: "None", est: "2020", techUsed: ["JavaScript", "HTML", "CSS"], link: "https://www.fullstackgrub.com/formquixi", more: false, extendedDescription: "" }],
      Color: 1,
      ActiveProject: {
        title: "",
        type: "",
        description: "",
        frontend: "",
        backend: "",
        est: "",
        techUsed: [],
        link: "",
        more: true,
        extendedDescription: ""
      }
    };
    this.lineRef = React.createRef();
    this.handlePage = this.handlePage.bind(this);
    this.headerAnimReset = this.headerAnimReset.bind(this);
    this.headerAnimHandler = this.headerAnimHandler.bind(this);
    this.handleColor = this.handleColor.bind(this);
    this.modalShow = this.modalShow.bind(this);
    this.LoadingWrapperRef = React.createRef();
    this.TransitionRef = React.createRef();
    this.TransitionAnimRef = React.createRef();
    this.ProjectsRef = React.createRef();
    this.MidContentRef = React.createRef();
    this.EducationRef = React.createRef();
    this.ContactRef = React.createRef();
    this.AboutMeRef = React.createRef();
  };
  headerAnimReset() {
    if (this.state.Home) {
      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "10px"
        this.lineRef.current.style.width = "500px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      }
      else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "0px"
        this.lineRef.current.style.width = "240px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      }
      else {
        this.lineRef.current.style.marginRight = "5px"
        this.lineRef.current.style.width = "370px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      }
    }
    if (this.state.Projects) {
      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "452px"
      }
      else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "207px"
      }
      else {
        this.lineRef.current.style.marginRight = "335px"
      }
      this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, red)"
    }
    if (this.state.Contact) {
      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "149px"
      }
      else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "48px"
      }
      else {
        this.lineRef.current.style.marginRight = "91px"
      }
      this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(106, 0, 255), rgb(106, 0, 255))"
    }
    if (this.state.Education) {
      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "302px"
      }
      else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "128px"
      }
      else {
        this.lineRef.current.style.marginRight = "214px"
      }
      this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(255, 0, 247), rgb(255, 0, 247))"
    }
  };

  headerAnimHandler(hover) {
    if (this.state.Home) {
      if (hover == 1) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "452px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "207px"
        }
        else {
          this.lineRef.current.style.marginRight = "335px"
        }
        this.lineRef.current.style.width = "5px"
        this.lineRef.current.style.height = "5px"
        this.lineRef.current.style.borderRadius = "5px"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, red)"
      }
      if (hover == 2) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "302px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "128px"
        }
        else {
          this.lineRef.current.style.marginRight = "214px"
        }
        this.lineRef.current.style.width = "5px"
        this.lineRef.current.style.height = "5px"
        this.lineRef.current.style.borderRadius = "5px"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(255, 0, 247), rgb(255, 0, 247))"
      }
      if (hover == 3) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "149px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "48px"
        }
        else {
          this.lineRef.current.style.marginRight = "91px"
        }
        this.lineRef.current.style.width = "5px"
        this.lineRef.current.style.height = "5px"
        this.lineRef.current.style.borderRadius = "5px"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(106, 0, 255), rgb(106, 0, 255))"
      }
      if (hover == 4) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "40px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "4px"
        }
        else {
          this.lineRef.current.style.marginRight = "12px"
        }
        this.lineRef.current.style.width = "5px"
        this.lineRef.current.style.height = "5px"
        this.lineRef.current.style.borderRadius = "5px"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(0, 132, 255), rgb(0, 132, 255))"
      }
    }
    if (this.state.Projects) {
      if (hover == 2) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "302px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "128px"
        }
        else {
          this.lineRef.current.style.marginRight = "214px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(255, 0, 247), rgb(255, 0, 247))"
      }
      if (hover == 3) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "149px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "48px"
        }
        else {
          this.lineRef.current.style.marginRight = "91px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(106, 0, 255), rgb(106, 0, 255))"
      }
      if (hover == 4) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "40px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "4px"
        }
        else {
          this.lineRef.current.style.marginRight = "12px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(0, 132, 255), rgb(0, 132, 255))"
      }
    }
    if (this.state.Education) {
      if (hover == 1) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "452px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "207px"
        }
        else {
          this.lineRef.current.style.marginRight = "335px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, red)"
      }
      if (hover == 3) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "149px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "48px"
        }
        else {
          this.lineRef.current.style.marginRight = "91px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(106, 0, 255), rgb(106, 0, 255))"
      }
      if (hover == 4) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "40px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "4px"
        }
        else {
          this.lineRef.current.style.marginRight = "12px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(0, 132, 255), rgb(0, 132, 255))"
      }
    }
    if (this.state.Contact) {
      if (hover == 1) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "452px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "207px"
        }
        else {
          this.lineRef.current.style.marginRight = "335px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, red)"
      }
      if (hover == 2) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "302px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "128px"
        }
        else {
          this.lineRef.current.style.marginRight = "214px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(255, 0, 247), rgb(255, 0, 247))"
      }
      if (hover == 4) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "40px"
        }
        else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "4px"
        }
        else {
          this.lineRef.current.style.marginRight = "12px"
        }
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, rgb(0, 132, 255), rgb(0, 132, 255))"
      }
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.TransitionRef.current.style.display = "none";
      this.LoadingWrapperRef.current.style.display = "none";
    }, 2500)

    if (window.visualViewport.width > 750) {
      this.lineRef.current.style.marginRight = "10px"
      this.lineRef.current.style.width = "500px"
      this.lineRef.current.style.borderRadius = "0"
      console.log(window.visualViewport.width)
    } else if (window.visualViewport.width < 500) {
      this.lineRef.current.style.marginRight = "0px"
      this.lineRef.current.style.width = "240px"
      this.lineRef.current.style.borderRadius = "0"
      console.log("rooster")
    } else {
      this.lineRef.current.style.marginRight = "5px"
      this.lineRef.current.style.width = "370px"
      this.lineRef.current.style.borderRadius = "0"
    }


    let self = this;
    window.addEventListener('resize', () => {
      if (self.state.Home) {
        if (window.visualViewport.width > 750) {
          this.lineRef.current.style.marginRight = "10px"
          this.lineRef.current.style.width = "500px"
          this.lineRef.current.style.borderRadius = "0"
        } else if (window.visualViewport.width < 500) {
          this.lineRef.current.style.marginRight = "0px"
          this.lineRef.current.style.width = "240px"
          this.lineRef.current.style.borderRadius = "0"
        } else {
          this.lineRef.current.style.marginRight = "5px"
          this.lineRef.current.style.width = "370px"
          this.lineRef.current.style.borderRadius = "0"
        }
      }
    })
  };
  handlePage(page) {
    this.LoadingWrapperRef.current.style.display = "block";
    this.TransitionRef.current.style.display = "block";
    if (page == 1) {
      this.MidContentRef.current.style.display = "block";
      this.ProjectsRef.current.style.display = "none";
      this.EducationRef.current.style.display = "none";
      this.ContactRef.current.style.display = "none";
      this.AboutMeRef.current.style.display = "none";

      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "10px"
        this.lineRef.current.style.width = "500px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      } else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "0px"
        this.lineRef.current.style.width = "240px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      } else {
        this.lineRef.current.style.marginRight = "5px"
        this.lineRef.current.style.width = "370px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      }

      this.setState({
        Home: true,
        Projects: false,
        Education: false,
        Contact: false,
      })
    }
    else if (page == 2) {
      this.MidContentRef.current.style.display = "none";
      this.ProjectsRef.current.style.display = "grid";
      this.EducationRef.current.style.display = "none";
      this.ContactRef.current.style.display = "none";
      this.AboutMeRef.current.style.display = "none";
      this.setState({
        Home: false,
        Projects: true,
        Education: false,
        Contact: false,
      })
    }
    else if (page == 3) {
      this.MidContentRef.current.style.display = "none";
      this.ProjectsRef.current.style.display = "none";
      this.EducationRef.current.style.display = "block";
      this.ContactRef.current.style.display = "none";
      this.AboutMeRef.current.style.display = "none";
      this.setState({
        Home: false,
        Projects: false,
        Education: true,
        Contact: false,
      })
    }
    else if (page == 4) {
      this.MidContentRef.current.style.display = "none";
      this.ProjectsRef.current.style.display = "none";
      this.EducationRef.current.style.display = "none";
      this.ContactRef.current.style.display = "block";
      this.AboutMeRef.current.style.display = "none";
      this.setState({
        Home: false,
        Projects: false,
        Education: false,
        Contact: true,
      })
    }
    else if (page == 5) {
      this.MidContentRef.current.style.display = "none";
      this.ProjectsRef.current.style.display = "none";
      this.EducationRef.current.style.display = "none";
      this.ContactRef.current.style.display = "none";
      this.AboutMeRef.current.style.display = "block";

      if (window.visualViewport.width > 750) {
        this.lineRef.current.style.marginRight = "10px"
        this.lineRef.current.style.width = "500px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      } else if (window.visualViewport.width < 500) {
        this.lineRef.current.style.marginRight = "0px"
        this.lineRef.current.style.width = "240px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      } else {
        this.lineRef.current.style.marginRight = "5px"
        this.lineRef.current.style.width = "370px"
        this.lineRef.current.style.height = "1px"
        this.lineRef.current.style.borderRadius = "0"
        this.lineRef.current.style.backgroundImage = "linear-gradient(to right, red, rgb(0, 162, 255))"
      }

      this.setState({
        Home: true,
        Projects: false,
        Education: false,
        Contact: false,
      })
    }
    setTimeout(() => {
      this.TransitionRef.current.style.display = "none";
      this.LoadingWrapperRef.current.style.display = "none";
    }, 2500)
  };
  handleColor() {
    if (this.state.Color == 1) {
      document.getElementById("logoID").style.backgroundColor = "brown"
      document.getElementById("projID").style.backgroundColor = "rgb(107, 107, 107)";
      var x = document.getElementsByClassName("ProjectSectionWrapper");
      for (let i = 0; i < x.length; i++) {
        x[i].style.borderImageSource = "linear-gradient(90deg, rgb(107, 107, 107), rgb(212, 212, 212), rgb(107, 107, 107))";
      }
      var y = document.getElementsByClassName("ProjectPic");
      for (let i = 0; i < y.length; i++) {
        y[i].style.borderImageSource = "linear-gradient(110deg, rgb(170, 170, 170),transparent, rgb(107, 107, 107))";
      }
      var z = document.getElementsByClassName("ProjectFooter");
      for (let i = 0; i < z.length; i++) {
        z[i].style.backgroundColor = "rgb(107, 107, 107)";
      }
      var a = document.getElementsByClassName("ProjectCardWrapper");
      for (let i = 0; i < a.length; i++) {
        a[i].style.borderImageSource = "linear-gradient(100deg, rgb(212, 212, 212), transparent, transparent)";
      }
      var b = document.getElementsByClassName("ProjectMidDesignTop");
      for (let i = 0; i < b.length; i++) {
        b[i].style.borderBottom = "1px solid rgb(107, 107, 107)";
      }
      // 
      var c = document.getElementsByClassName("ProjectFooterSection");
      for (let i = 0; i < c.length; i++) {
        c[i].style.borderImageSource = "linear-gradient(90deg,rgb(107, 107, 107),  rgb(180, 180, 180), rgb(107, 107, 107))";
      }
      var d = document.getElementsByClassName("ProjectFooterTitle");
      for (let i = 0; i < d.length; i++) {
        d[i].style.borderImageSource = "linear-gradient(90deg,rgb(107, 107, 107),  rgb(200, 200, 200), rgb(107, 107, 107))";
      }
      var e = document.getElementsByClassName("ProjectFooterDesc");
      for (let i = 0; i < e.length; i++) {
        e[i].style.borderImageSource = "linear-gradient(90deg,rgb(107, 107, 107),  rgb(212, 212, 212), rgb(107, 107, 107))";
      }
      // 
      document.body.style.backgroundColor = "rgb(107, 107, 107)";
      this.TransitionAnimRef.current.style.backgroundColor = "rgb(107, 107, 107)";
      const num = this.state.Color + 1;
      this.setState({
        Color: num,
      })
    }
    else {
      document.getElementById("logoID").style.backgroundColor = "rgb(40, 28, 53)"
      document.getElementById("projID").style.backgroundColor = "rgb(12,10,15)";

      var x = document.getElementsByClassName("ProjectSectionWrapper");
      for (let i = 0; i < x.length; i++) {
        x[i].style.borderImageSource = "linear-gradient(90deg, rgb(12, 10, 15),rgb(41, 34, 49), rgb(12, 10, 15))";
      }
      var y = document.getElementsByClassName("ProjectPic");
      for (let i = 0; i < y.length; i++) {
        y[i].style.borderImageSource = "linear-gradient(110deg, rgb(170, 170, 170),transparent, rgb(12, 10, 15))";
      }
      var z = document.getElementsByClassName("ProjectFooter");
      for (let i = 0; i < z.length; i++) {
        z[i].style.backgroundColor = "rgb(12, 10, 15)";
      }
      var a = document.getElementsByClassName("ProjectCardWrapper");
      for (let i = 0; i < a.length; i++) {
        a[i].style.borderImageSource = "linear-gradient(100deg, rgb(40, 30, 49), transparent, transparent)";
      }
      var b = document.getElementsByClassName("ProjectMidDesignTop");
      for (let i = 0; i < b.length; i++) {
        b[i].style.borderBottom = "1px solid rgb(12, 10, 15)";
      }
      // 
      var c = document.getElementsByClassName("ProjectFooterSection");
      for (let i = 0; i < c.length; i++) {
        c[i].style.borderImageSource = "linear-gradient(90deg,rgb(12, 10, 15),  rgb(29, 22, 36), rgb(12, 10, 15))";
      }
      var d = document.getElementsByClassName("ProjectFooterTitle");
      for (let i = 0; i < d.length; i++) {
        d[i].style.borderImageSource = "linear-gradient(90deg,rgb(12, 10, 15),  rgb(40, 30, 49), rgb(12, 10, 15))";
      }
      var e = document.getElementsByClassName("ProjectFooterDesc");
      for (let i = 0; i < e.length; i++) {
        e[i].style.borderImageSource = "linear-gradient(90deg,rgb(12, 10, 15),  rgb(67, 51, 82), rgb(12, 10, 15))";
      }
      //
      document.body.style.backgroundColor = "rgb(12,10,15)"
      this.TransitionAnimRef.current.style.backgroundColor = "rgb(12,10,15)";
      const num = this.state.Color - 1;
      this.setState({
        Color: num,
      })
    }
  }
  modalShow(data) {
    let modal = document.getElementById("modal");
    let wind = document.getElementById("sw");
    modal.classList.add("ActiveModal")
    wind.addEventListener("click", e => {
      if (modal !== e.target && !modal.contains(e.target)) {
        modal.classList.remove("ActiveModal")
        this.setState({
          Modal: false
        })
        wind.removeEventListener("click", this.modalShow)
      }
    })
    this.setState({
      Modal: true,
      ActiveProject: {
        title: data.title,
        type: data.type,
        description: data.description,
        frontend: data.frontend,
        backend: data.backend,
        est: data.est,
        techUsed: data.techUsed,
        link: data.link,
        more: data.more,
        extendedDescription: data.extendedDescription
      }
    })
  }
  render() {
    const self = this;
    window.addEventListener('resize', this.headerAnimReset);
    const ProjectListMapped = this.state.ProjectsList.map((i) => <Projects data={i} FooterRef={this.ProjectFooterRef} modalShow={this.modalShow} />);
    return (
      <div className="SuperWrapper" id="sw">
        <Modal data={this.state.ActiveProject} />
        <div className="LoadingWrapper" ref={this.LoadingWrapperRef}>
          <div className="LoadingThing"></div>
        </div>
        <div className="TransitionSuperWrapper" ref={this.TransitionRef}>
          <div className="TransitionMainWrapper" ref={this.TransitionAnimRef}></div>
        </div>
        <div className="Logo" onClick={() => self.handlePage(1)} id="logoID"></div>
        <div className="HeaderWrapper">
          <div className="HeaderItem" onMouseOver={() => self.headerAnimHandler(1)} onMouseOut={() => self.headerAnimReset()} onClick={() => self.handlePage(2)}>Projects</div>
          <div className="HeaderItem" onMouseOver={() => self.headerAnimHandler(2)} onMouseOut={() => self.headerAnimReset()} onClick={() => self.handlePage(3)}>Education</div>
          <div className="HeaderItem" onMouseOver={() => self.headerAnimHandler(3)} onMouseOut={() => self.headerAnimReset()} onClick={() => self.handlePage(4)}>Contact</div>
          <div className="HeaderItem" onMouseOver={() => self.headerAnimHandler(4)} onMouseOut={() => self.headerAnimReset()} onClick={() => self.handleColor()}><i class="far fa-lightbulb"></i></div>
        </div>
        <div className="RightHeaderWrapper">
          <div className="GitHubButton"><a href="https://github.com/Grub1000"><i class="fab fa-github fa-2x"></i></a></div>
          <div className="LinkedInButton"><a href="https://www.linkedin.com/in/jorge-ramirez-02363a18b/"><i class="fab fa-linkedin fa-2x"></i></a></div>
        </div>
        <div className="HeaderLineThingWrapper"><hr className="HeaderLineThing" ref={this.lineRef}></hr></div>
        <div className="MidContentWrapper" ref={this.MidContentRef}>
          <div className="MidContent">
            <div className="MidContentName">
              <div className="MidContentNameLetter">J</div>
              <div className="MidContentNameLetter">o</div>
              <div className="MidContentNameLetter">r</div>
              <div className="MidContentNameLetter">g</div>
              <div className="MidContentNameLetter">e</div>
              <div className="MidContentNameLetter"></div>
              <div className="MidContentNameLetter">R</div>
              <div className="MidContentNameLetter">a</div>
              <div className="MidContentNameLetter">m</div>
              <div className="MidContentNameLetter">i</div>
              <div className="MidContentNameLetter">r</div>
              <div className="MidContentNameLetter">e</div>
              <div className="MidContentNameLetter">z</div>
            </div>
            <div className="MidContentProffesion">Full-Stack Web Developer</div>
            <div className="MidContentAbout" onClick={() => self.handlePage(5)}>About Me</div>
          </div>
        </div>
        <div className="ProjectsSuperWrapper" ref={this.ProjectsRef} id="projID">
          <div class="ProjectsMainAnim"></div>
          <div class="ProjectsMainAnim2"></div>
          <div className="ProjectsWrapper">
            {ProjectListMapped}
            <div style={{ height: '50px' }}></div>
          </div>
        </div>
        <div className="EducationSuperWrapper" ref={this.EducationRef}><Education handle={this.handlePage} /></div>
        <div className="ContactSuperWrapper" ref={this.ContactRef}><Contact /></div>
        <div className="AboutMeSuperWrapper" ref={this.AboutMeRef}><About /></div>
      </div>
    )
  }
}