import React from 'react';

import './TimeLineNode.css';

export default class TimeLineNode extends React.Component {
    constructor() {
        super();
        this.state = {

        }
        this.nodeShow = this.nodeShow.bind(this);
    }
    nodeShow(index) {
        let node = document.getElementById(index.toString());
        node.classList.remove("InactiveNode")
        node.classList.add("ActiveNode")
    }
    nodeHide(index) {
        let node = document.getElementById(index.toString());
        node.classList.remove("ActiveNode")
        node.classList.add("InactiveNode")
        setTimeout(() => node.classList.remove("InactiveNode"), 300)
    }
    render() {
        return (
            <div className="TimeLineNode" onMouseEnter={() => this.nodeShow(this.props.index)} onMouseLeave={() => this.nodeHide(this.props.index)}>
                <div className="TimeLineNodeContentWrapper" id={this.props.index}>
                    <div className="TimeLineNodeContentDate">{this.props.data.date}</div>
                    <div className="TimeLineNodeContentTitle">{this.props.data.title}</div>
                    <div className="TimeLineNodeContentDescription">{this.props.data.description}</div>
                </div>
            </div>
        )
    }
}