import React from 'react';
import './Education.css';

import TimeLineNode from "./TimeLineNode.js";

export default class Education extends React.Component {
    constructor() {
        super();
        this.state = {
            timeLineNodes: [
                { left: 117, date: "May", title: "Graduated Highschool", description: "" },
                { left: 187, date: "August", title: "Attended Community College", description: "I was a lost kid not sure of what I was chasing. This is was what felt right at the time." },
                { left: 303, date: "January", title: "Dropped Out of College", description: "I was not sure what to pursue in life so I took a step back to relax and think of my future in a proper manner." },
                { left: 350, date: "March", title: "Moment of Clarity", description: "I realized that I was insanely passionate about mathematics and technology. There was no force that could stop what I was about to do next." },
                { left: 373, date: "April", title: "Reading Books", description: "After a long process of trying to match my passions to a skillset & career path I fell upon software engineering as a clear path to success. The book worming starts." },
                { left: 420, date: "June", title: "Learning Python", description: "The day I wrote my first python print statement ( print('Hi Jorge')) and instantly fell in love." },
                { left: 536, date: "November", title: "Learning Django", description: "After many reading breaks and continuous practice with python I decided to learn Django for Web Development." },
                { left: 583, date: "January", title: "Reading Again", description: "I began reading CS books a whole lot during this time to really obtain fundamental prowess in algorithmic problem solving." },
                { left: 653, date: "April 9", title: "Joined GitHub", description: "I remember this day very clearly because it was also the day I completed and deployed the first iteration of my website." },
                { left: 677, date: "May", title: "Learning React / NodeJS", description: "Began learning React as Django- Templates with JS and CSS were not meeting my needs." },
                { left: 793, date: "October", title: "Learning PHP", description: "A friend of mine let me see some PHP code and with Python & Javascript being so similar to PHP, I decided to learn PHP aswell." },
                { left: 933, date: "April", title: "Learning Laravel", description: "I began learning Laravel for a job and within a week I had created my first Laravel project and obtained my position as a Fullstack Web Developer." },
                { left: 1027, date: "August", title: "Work Project Finished", description: "After working at Greenstaar for some time and after completion of my tasks at the company I was let go." },
                { left: 1073, date: "October", title: "Learning Ruby", description: "Learning the Ruby language and trying to see where Ruby can lend itself in future projects." },
                { left: 1094, date: "November 17", title: "Making This", description: "I wanted to create something that told my learning story In a chronological manner instead of just saying I'm self-taught." },
                { left: 1213, date: "April 17", title: "New Project & Good News", description: "The code I wrote for thegroapp.com Label Generator module was recently put through its first real production run by a client and it ran smoothly." },
            ]
        };
        this.nodePositionListener = this.nodePositionListener.bind(this);
    };
    componentDidMount() {
        const nodeList = document.querySelectorAll('.TimeLineNode');
        let self = this;
        Array.prototype.forEach.call(nodeList, function (node, index) {
            if (window.visualViewport.width < 1400) {
                node.style.marginLeft = (self.state.timeLineNodes[index].left * 0.6).toString() + "px";
            } else {
                node.style.marginLeft = self.state.timeLineNodes[index].left.toString() + "px";
            }
        })
        window.addEventListener('resize', this.nodePositionListener)
    }
    nodePositionListener() {
        const nodeList = document.querySelectorAll('.TimeLineNode');
        let self = this;
        Array.prototype.forEach.call(nodeList, function (node, index) {
            if (window.visualViewport.width < 1400) {
                node.style.marginLeft = (self.state.timeLineNodes[index].left * 0.6).toString() + "px";
            } else {
                node.style.marginLeft = self.state.timeLineNodes[index].left.toString() + "px";
            }
        })
    }
    render() {
        const self = this;
        return (
            <div className="EducationSectionWrapper">
                {/* <div className="EducationMessage">100% Self Taught</div>
                <div className="EducationAbout" onClick={() => self.props.handle(1)}>About Me</div> */}
                <div className="TimeLineFeatureSuperWrapper">
                    <div className="TimeLineWrapper">
                        <div className="TimeLineBeginningText">2018</div>
                        <div className="TimeLineInBetweenYearWrapper"><div className="TimeLineInBetweenYearText">2019</div></div>
                        <div className="TimeLineInBetweenYearWrapper"><div className="TimeLineInBetweenYearText">2020</div></div>
                        <div className="TimeLineInBetweenYearWrapper"><div className="TimeLineInBetweenYearText">2021</div></div>
                        <div className="TimeLineInBetweenYearWrapper"><div className="TimeLineInBetweenYearText">2022</div></div>
                        <div className="TimeLinePresentText">2023</div>
                        <div className="TimeLineNodesWrapper">
                            {this.state.timeLineNodes.map((data, index) => <TimeLineNode data={data} key={index} index={index} />)}
                        </div>
                    </div>
                </div>
                {/* <div class>yo</div> */}
            </div>
        )
    }
}